// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('无忧住 ⚡️ 无忧住管理端')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
import { createConsumer } from "@anycable/web";

let consumer;

const createCable = () => {
  if (!consumer) {
    consumer = createConsumer();
  }

  return consumer;
};

const createChannel = (...args) => {
  const consumer = createCable();
  return consumer.subscriptions.create(...args);
};


(function () {
  window.App || (window.App = {});
  var _ws_url;
  if (window.gon) {
    _ws_url = window.gon.ws_url || "/cable";
  } else {
    _ws_url = "/cable";
  }
  window.App = {
    createChannel: createChannel
  }
}).call(this);